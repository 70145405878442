import React, { useEffect } from "react"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU13Colts() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Under 13's - Colts</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u13-colts.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
            <p>
                This will be our 5th season with the boys in the Garforth league and the large majority of them have come from our extremely successful Kindergarten. We aim to play an attractive brand of football which is built from the back and have developed the boys so they can adapt to any number of positions they are asked to play in.
            </p>
            <p>
                All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
            </p>
            </div>

            <div className="row border thefa-table g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 ">
            <h3>League Table</h3>
            <TheFATable leagueId="656616074"></TheFATable>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 8, DoB 01/09/2008 - 31/08/2009</dd>
                <dt>Manager(s)</dt>
                <dd>Dan Peacock</dd>
                <dt>Training</dt>
                <dd>Bedquilts Saturday 9:30am till 10:30am</dd>
                <dt>League</dt>
                <dd>Garforth Junior League - 9 aside</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:dpeacock82@yahoo.co.uk">dpeacock82@yahoo.co.uk</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-iconsultyorkshire.jpg" />
                <div className="card-body">
                    <p className="card-text">I Consult Yorkshire Ltd is a Business, Education and Skills consultancy based in Leeds, set up by leading management consultant Ewan Metcalf.</p>
                    <div className="btn-group">
                    <a href="http://www.iconsultyorkshire.co.uk/home" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

